import React from "react";

import { faGears, faQuestion, faUser } from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";

import styles from "@/assets/styles/pages/home.module.scss";
import Button from "@/shared/components/Button";
import SectionIcon from "@/shared/components/SectionIcon";

const data = [
    {
        id: 1,
        icon: faQuestion,
        color: "#FD7278",
        title: "What is The Gender Index?",
        content: `
            <p>
                Mapping the impact of female-led companies on the UK economy is crucial for a stronger, more inclusive economy.
                Yet, a freely available tool to measure their number, potential, and support their growth has been lacking.            
            </p>
            <p>
                Enter our groundbreaking data source, which benchmarks female entrepreneur activity and spotlights the gender gap in entrepreneurship. 
                Join us in using this free data to empower women and bridge the divide.
            </p>
    `,
        href: "/news/five-ways-the-gender-index-data-can-help-you?page=4",
    },
    {
        id: 2,
        icon: faGears,
        color: "#00A7B7",
        title: "How does it work?",
        content: `
            <p>
                Our AI-powered tool is one-of-a-kind, mapping all 5.2+ million active UK companies in real-time. With customizable search options, 
                explore insights by gender, sector, investment type, ethnic minority leadership, and more.
            </p>
            <p>
                Track progress, growth, and trends by various criteria instantly. All the data you need is right at your fingertips.
            </p>
    `,
        href: "/news/five-ways-the-gender-index-data-can-help-you?page=4",
    },
    {
        id: 3,
        icon: faUser,
        color: "#FFC000",
        title: "Who is it for?",
        content: `
            <p>
                Whether you're a female founder seeking growth, a policymaker, journalist, educator, corporate entity, or investor, we've got you covered.
            </p>
            <p>
                The Gender Index is freely accessible and open for use. Download our annual reports or craft and distribute your customized reports to spark dialogue, ideas, and policy actions. 
                Together, let's propel towards a more equitable future.            
            </p>
    `,
        href: "/news/five-ways-the-gender-index-data-can-help-you?page=4",
    },

];
function WhatIsGenderIndex() {
    return (
        <div className={styles["site-info"]}>
            <div className="container">
                <div className={styles["site-info-main"]}>
                    {
                        data.map((i, index) => (
                            <div key={i.id} className={styles["site-info-cont"]}>
                                <div className={styles["site-info-icon-cont"]}>
                                    <SectionIcon
                                        background={i.color}
                                        icon={i.icon} />
                                </div>
                                <div className={`${styles["site-info-content-cont"]} ${index === data.length - 1 ? styles["site-info-no-border"] : ""}`}>
                                    <div className={styles["site-info-title"]}>{i.title}</div>
                                    <div className={styles["site-info-content"]} dangerouslySetInnerHTML={{ __html: i.content }} />
                                </div>
                                <div className={styles["site-info-btn-cont"]}>
                                    <Link href={i.href}>
                                        <Button
                                            variant="outlined" color="red">
                                            Learn more
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default WhatIsGenderIndex;
