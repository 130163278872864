import React from "react";

import { faBookOpenReader, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";

import styles from "@/assets/styles/pages/home.module.scss";
import { APP_ROUTES } from "@/constants/routes";

function DelveIntoData() {

    return (
        <div className={styles["delve-data"]}>
            <div className="container">
                <div className="grid-wrapper">

                    <div className="row">
                        <div className={styles["delve-data-left"]}>
                            <Image
                                src="/images/delve_left.png"
                                alt="home image"
                                width={300}
                                height={300}
                                loading="lazy"
                            />
                        </div>
                        {/* <div className={`${styles["delve-data-text-overlay"]} row`}>

                        </div> */}
                        <div className={styles["delve-data-container"]}>
                            <div className="col-12 col-lg-6">
                                <div className={styles["delve-data-title"]}>
                                    Delve into the data yourself:
                                </div>
                                <p className={styles["delve-data-summary"]}>
                                    Explore gender-disaggregated data beyond the UK national level through our interactive dashboard.
                                    Discover insights at the regional and local authority levels, or dive into the latest TGI report for comprehensive analysis of the UK gender landscape.
                                </p>
                                {/* <button
                                    className={`${styles["delve-data-button"]} btn-red-transparent`}>
                                    Find out more
                                </button> */}
                                <div className='actionBtnsWrap'>
                                    <Link href={APP_ROUTES.explore}>
                                        <button
                                            className={`${styles["delve-data-button"]} btn-red-outlined`}>
                                            <FontAwesomeIcon icon={faDatabase} /> Explore the data
                                        </button>
                                    </Link>
                                    <Link href="/reports">
                                        <button
                                            className={`${styles["delve-data-button"]} btn-blue-outlined`}>
                                            <FontAwesomeIcon icon={faBookOpenReader} /> Read latest report
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <Image
                                    className={`${styles["delve-data-stat-image"]}`}
                                    src="/images/delve_data_2025.png"
                                    alt="home image"
                                    width={1250}
                                    height={500}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className={styles["delve-data-right"]}>
                            <Image
                                src="/images/uk_story-left.png"
                                alt="home image"
                                width={412}
                                height={278}
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DelveIntoData;

