import React from "react";

import Image from "next/image";
import Link from "next/link";

import styles from "@/assets/styles/pages/home.module.scss";

function SecuredDebt() {
    return (
        <div className={styles["partners"]}>
            <div className="container">
                <div className={"grid-wrapper"}>
                    <div className="row">
                        <div className="col-12">
                            <div className={styles["partners-title"]}>Made possible by</div>
                        </div>
                        <div className="col-12">
                            <ul className={`row ${styles["partners-logo"]}`}>
                                <li className="col-6 col-sm-6 col-lg-4 col-xl-2 aws-icon">
                                    <Link href="/headline-sponsors/amazon-web-services/">
                                        <Image
                                            src="/images/PartnerLogos/aws-new.png"
                                            alt="aws"
                                            width={80}
                                            height={50}
                                            loading="lazy"
                                            quality={100}
                                        />
                                    </Link>
                                </li>
                                <li className="col-6 col-sm-6 col-lg-4 col-xl-2 goldman-icon">
                                    <Link href="/headline-sponsors/goldman-sachs/">
                                        <Image
                                            src="/images/PartnerLogos/goldman-new.jpg"
                                            alt="goldman-sachs"
                                            width={145}
                                            height={40}
                                            loading="lazy"
                                            quality={100}
                                        />
                                    </Link>
                                </li>
                                <li className="col-6 col-sm-6 col-lg-4 col-xl-2 hsbc-icon">
                                    <Link href="/strategic-partners/hsbc/">
                                        <Image
                                            src="/images/PartnerLogos/hsbc.png"
                                            alt="hsbc"
                                            width={260}
                                            height={50}
                                            loading="lazy"
                                            quality={100}
                                        />
                                    </Link>
                                </li>
                                <li className="col-6 col-sm-6 col-lg-4 col-xl-2 mnai-icon">
                                    <Link href="/strategic-partners/mnai/">
                                        <Image
                                            src="/images/PartnerLogos/mnai-new.png"
                                            alt="mnai"
                                            width={150}
                                            height={60}
                                            loading="lazy"
                                            quality={100}
                                        />
                                    </Link>
                                </li>
                                <li className="col-6 col-sm-6 col-lg-4 col-xl-2">
                                    <Link href="/headline-partners/natwest/">
                                        <Image
                                            // src="/images/PartnerLogos/NWG_Horizontal_RGB_POS.png"
                                            src="/images/PartnerLogos/natwest-new.png"
                                            alt="nat-west-group"
                                            className="nat-logo-home"
                                            width={240}
                                            height={58}
                                            loading="lazy"
                                            quality={100}
                                        />
                                    </Link>
                                </li>
                                <li className="col-6 col-sm-6 col-lg-4 col-xl-2 smith-icon">
                                    <Link href="/headline-sponsors/dwf">
                                        <Image
                                            src={"/images/PartnerLogos/dwf.png"}
                                            alt="DWF"
                                            width={150}
                                            height={60}
                                            loading="lazy"
                                            quality={100}
                                        />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SecuredDebt;
